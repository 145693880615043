import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import Feature from '../containers/Feature';
import SEO from '../components/seo';
import WorkingProcessSection from '../containers/WorkingProcess';
import { useTranslation } from 'react-i18next';

export default ({ pageContext }) => {
  const { t } = useTranslation();
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        PROCESS_ITEMS {
          title
          alt
          description
          image {
            publicURL
          }
        }
        ENTERPRISE_FEATURES {
          title
          alt
          description
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Features for users')}
          lang={pageContext.lang}
          description={t(
            'A list of features of the Psono password manager. It displays the rich set of functionality that Psono has to offer.'
          )}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Key Features')}
            descriptionText={t('What makes this password manager secure.')}
          />
          <WorkingProcessSection
            items={Data.saasModernJson.PROCESS_ITEMS}
            pageContext={pageContext}
          />
          <Feature />
          <WorkingProcessSection
            title={t('Enterprise Features')}
            description={t(
              'Features available exclusively in our Enterprise Edition (EE)'
            )}
            items={Data.saasModernJson.ENTERPRISE_FEATURES}
            pageContext={pageContext}
          />
          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
